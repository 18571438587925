<template>
    <div id="config-ticket" class="container-fluid">
        <div class="row ">
            <titles title="Configuración" subtitle="Tickets" ></titles>
            <title-tabs :array_titletabs="title_ticket"></title-tabs>
            <div class="col-12 bg-white shadow-sm py-3 px-3 rounded border-box">
                <div class="row p-0">
                    <span class="text-black opensans-semibold ki--description-sm">Ajustes de impresora</span>
                    <div class="col-12 p-0 bg-lightgray mt-2">
                        <div class="row">
                            <div class="col-3 py-2" 
                                v-for="(item_ticket, index_ticket) in array_ticket" :key="index_ticket" >
                                <ki-checkbox 
                                    :label="item_ticket.name" fill class="text-black opensans-semibold" 
                                    :value="item_ticket.check"
                                    style="font-size:13px;" >
                                </ki-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 mt-2">
                        <div class="row p-0">
                            <div class="col-6 col-xxl-4 p-0">
                                <div class="px-2 py-1 border-2 border-light"  style="height:130px">
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">Código a imprimir</span>
                                    <div class="mt-2">
                                        <div class="d-flex justify-content-start align-items-center pl-1" v-for="(item_codeprinter, index_codeprinter) in array_codeprinter" :key="index_codeprinter">
                                            <input type="radio" :id="`typeproduct_${index_codeprinter}`" name="codeprinter" :checked="item_codeprinter.check">
                                            <label :for="`typeproduct_${index_codeprinter}`" class=" title_selects label_checkbox mt-1 ml-2">{{item_codeprinter.name}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <span class="text-black opensans-semibold ki--description-sm">Impresora</span>
                                    <ki-select 
                                        v-model="printer"
                                        :options="[{id: 1, label: 'Value1'}, {id: 2, label: 'Value2'}]" 
                                        hidden_option="Seleccione"
                                        full class="opensans-semibold mt-1">
                                    </ki-select>
                                </div>
                                <div class="mt-2">
                                    <span class="text-black opensans-semibold ki--description-sm mt-3">Copias</span>
                                    <ki-select 
                                        v-model="quantity"
                                        :options="[{id: 1, label: 'Value1'}, {id: 2, label: 'Value2'}]" 
                                        full class="opensans-semibold mt-1">
                                    </ki-select>
                                </div>
                                <div class="mt-2 d-flex">
                                    <ki-checkbox label="Usar impresión dual" fill class="text-black opensans-semibold" style="font-size:13px"  :value="true"></ki-checkbox>
                                </div>
                                <div class="px-2 py-1 border-2 border-light mt-2"  style="height:100px">
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">1ra. opción Impresora de Tickets</span>
                                    <br>
                                    <span class="text-black opensans-semibold mb-3" style="font-size: 13px;">2da. opción impresota láser de nombre:</span>
                                    <div class="mt-2">
                                        <ki-input type="string" placeholder="Ingrese nombre" full class="opensans-semibold"> </ki-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <div class="row p-0 col d-flex justify-content-end align-items-center">
                            <div>
                                <button class="pills bg-primary-dark px-4 py-2 mt-3 mr-4 text-white">Guardar cambios</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //Components
    import Titles from '../../components/Titles.vue'
    import TitleTabs from '../../components/TitleTabs.vue'

    //Icons
    import icon_write from '../../assets/icons/icon_write.png';
    import icon_delete_red from '../../assets/icons/icon_delete_red.png'; 

export default {
    name: 'ki-config-ticket',
    components: {
        'titles': Titles,
        'title-tabs': TitleTabs,
    },
    data() {
        return {
            title_ticket: [
                { name: 'Ventas a crédito', selected: true },
            ],
            titletab_active: null,
            array_ticket:[
                { name: 'Imprimir ticket de venta', check: true },
                { name: 'Previsualizar ticket', check: true },
            ],
            array_codeprinter:[
                { name: 'Impresora de tickets (Mini printer)', check: true },
                { name: 'Impresora regular (Laser, inyección, Matriz, etc)', check: false },
                { name: 'Código de proveedor', check: false }
            ],
            printer: 'Seleccione',
            quantity: 'Seleccione'
        };
    },
    methods: {
        
    },
    created(){
        for (let i in this.title_ticket) {
            if(this.title_ticket[i].selected){
                this.titletab_active = this.title_ticket[i].name
            }
        }
    }
};
</script>